import httpClient from "./axiosBase";

import { spacesAPI } from "../interfaces/spaces";

const baseUrl = "/spaces";

const spaces: spacesAPI = {
  async setSpaceSearchEntity({ id, query }) {
    return await httpClient.post(`${baseUrl}/${id}/searches`, { query });
  },
  async getSpaceProjects({ id, limit, offset }) {
    return await httpClient.get(`${baseUrl}/${id}/projects`, {
      params: { limit, offset }
    });
  },
  async getSpaceDomains({ id, limit, offset }) {
    return await httpClient.get(`${baseUrl}/${id}/domains`, {
      params: { limit, offset }
    });
  },
  async getSpaceSearches({ id, limit, offset, searchType }) {
    return await httpClient.get(`${baseUrl}/${id}/searches`, {
      params: { limit, offset, searchType }
    });
  }
};

export default spaces;
