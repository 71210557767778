import React, { useEffect } from "react";
import { ProjectsContainer } from "../Dashboard/ProjectsContainer";
import BreadCrumbWrapper from "../../hoc/BreadCrumbWrapper";
import { DOC_TITLE_SEPARATOR, APP_NAME } from "../../consts";

const ProjectsComponent: any = (props: any) => {
  useEffect(() => {
    document.title = `Projects ${DOC_TITLE_SEPARATOR} ${APP_NAME}`;
  });
  return <ProjectsContainer />;
};

export default BreadCrumbWrapper()(ProjectsComponent);
