import { takeLatest, put, call } from "redux-saga/effects";
import { orgAPI } from "../api";
import { getOrgId } from "./miscellanious";
import { reduxAction } from "../interfaces";
import {
  GET_ORG_SPACES,
  SEARCH_SPACE_BY_NAME,
  GET_ALL_ORG_PROJECTS
} from "../actionKeys";
import { setSpacesData, setSpacesCount } from "../actions/spacesActions";
import { setProjects, setProjectsCount } from "../actions/projectsActions";
import { DEFAULT_PAGE_SIZE } from "../consts";
import { notifyError } from "../utils";

function* setOrgSpaces(data: any) {
  const { count, results } = data;
  if (count) {
    yield put(setSpacesCount(count));
    yield put(setSpacesData(results));
  }
}

function* getOrgSpaces(action: reduxAction) {
  const { toggleSpaceLoading, offset } = action.payload;
  try {
    const id = yield call(getOrgId);
    if (id) {
      const response = yield call(orgAPI.getAllSpaces, id, offset);
      const { data } = response;
      if (data) {
        yield call(setOrgSpaces, data);
      }
    }
    yield call(toggleSpaceLoading, false);
  } catch (error) {
    yield call(notifyError, error);
  }
}

function* searchSpacesByName(action: reduxAction) {
  const { query, toggleSpaceLoading } = action.payload;
  if (query && query.length > 1) {
    try {
      const orgId = yield call(getOrgId);
      if (orgId) {
        const response = yield call(orgAPI.searchSpaces, orgId, query);
        const { data } = response;
        if (data) {
          yield call(setOrgSpaces, data);
        }
      }
      yield call(toggleSpaceLoading, false);
    } catch (error) {
      yield call(notifyError, error);
    }
  }
}

function* getOrgProjects(action: reduxAction) {
  const { bodyParams, toggleLoading } = action.payload;
  bodyParams.limit = DEFAULT_PAGE_SIZE;
  try {
    const orgId = yield call(getOrgId);
    if (orgId) {
      const requestParams = { ...bodyParams, orgId };
      const response = yield call(orgAPI.getOrgProjects, requestParams);
      const { data } = response;
      if (data && data.count) {
        const { count, results } = data;
        yield put(setProjectsCount(count));
        yield put(setProjects(results));
      }
    }
  } catch (error) {
    yield call(notifyError, error);
  } finally {
    yield call(toggleLoading, false);
  }
}

export default function* organizationSaga() {
  yield takeLatest(GET_ORG_SPACES, getOrgSpaces);
  yield takeLatest(SEARCH_SPACE_BY_NAME, searchSpacesByName);
  yield takeLatest(GET_ALL_ORG_PROJECTS, getOrgProjects);
}
