// spaces related actions
export const CREATE_SPACE = "CREATE_SPACE";
export const ASSOSCIATE_QUERY_TO_SPACE = "ASSOSCIATE_QUERY_TO_SPACE";
export const SEARCH_SPACE_BY_NAME = "SEARCH_SPACE_BY_NAME";
export const SET_SPACES_DATA = "SET_SPACES_DATA";
export const SET_SPACES_COUNT = "SET_SPACES_COUNT";
export const RESET_SPACES = "RESET_SPACES";
export const GET_PROJECTS_BY_SPACE_ID = "GET_PROJECTS_BY_SPACE_ID";
export const GET_DOMAINS_BY_SPACE_ID = "GET_DOMAINS_BY_SPACE_ID";
export const GET_ACTIVE_SEARCHES_BY_SPACE_ID =
  "GET_ACTIVE_SEARCHES_BY_SPACE_ID";
export const GET_INACTIVE_SEARCHES_BY_SPACE_ID =
  "GET_INACTIVE_SEARCHES_BY_SPACE_ID";
export const SET_ACTIVE_SEARCHES = "SET_ACTIVE_SEARCHES";
export const SET_INACTIVE_SEARCHES = "SET_INACTIVE_SEARCHES";
