import React from "react";
import { Tag, Typography, Button } from "antd";
import styled from "styled-components";
import { ScanStatus } from "../../enums";
import { Tag as TagType, ProjectItem } from "../../interfaces";
import { SpacedFlex, StyledCard } from "../Generics/InfoCard";
import { default as moment } from "moment";

// const IndicatorCount = styled.span`
//   margin: 4px;
// `;

// const CountNumber = styled.span`
//   margin-left: 4px;
//   font-weight: bold;
// `;

const CardTags = styled.div`
  width: 400px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.2rem;
  .ant-tag {
    height: 22px;
    margin: 0.2rem;
    &:first-of-type {
      margin-left: 0;
    }
  }
`;

interface ProjectCardProps {
  data: ProjectItem;
  id: number;
  status: number;
  tags: Array<any>;
  title: string | undefined;
  domainCount: number;
  createdOn: string;
  cardClickHandler: (projectId: number) => void;
}

export const ProjectCard: any = (props: ProjectCardProps) => {
  const { data, cardClickHandler } = props;
  const {
    id: projectId,
    status,
    tags,
    comment: title,
    domain: domainCount,
    created_on: createdOn
  } = data;
  const isScanning = status === ScanStatus.SCANNING;
  // const isScanningDone = status === ScanStatus.SCANNING_DONE;
  const isReadyToScan = status === ScanStatus.CRAWLING_DONE;

  const CardHeader = (
    <SpacedFlex>
      <Typography.Title level={4} ellipsis>
        {title}
      </Typography.Title>
      <CardTags>
        {tags &&
          tags.map((tag: TagType, index: number) => {
            return (
              <Tag key={`${tag.slug}-${index}`} color={tag.color}>
                {tag.name}
              </Tag>
            );
          })}
      </CardTags>
      <div style={{ display: "flex" }}>
        {createdOn ? (
          <React.Fragment>
            <div>
              <p>Created On</p>
              {/* {isScanningDone && <p>Last Scan On</p>} */}
            </div>
            <div style={{ marginLeft: 4 }}>
              <p>{moment(createdOn).format("DD-MMM-YYYY")}</p>
              {/* {isScanningDone && <p>11/09/2019</p>} */}
            </div>
          </React.Fragment>
        ) : (
          <div style={{ minWidth: 195 }} />
        )}
      </div>
    </SpacedFlex>
  );
  return (
    <StyledCard
      title={CardHeader}
      onClick={projectId ? cardClickHandler.bind(null, projectId) : () => null}
    >
      <SpacedFlex>
        <p>
          {isScanning ? "Scanning " : null}
          <strong>Domain Id: </strong> {domainCount}
        </p>
        <div>
          {isScanning ? <span>{"Scanning..."}</span> : null}
          {/* {isScanningDone ? (
            <React.Fragment>
              <IndicatorCount>
                <Icon type="warning" style={{ color: "#FDCC5D" }} />
                <CountNumber>2</CountNumber>
              </IndicatorCount>
              <IndicatorCount>
                <Icon type="close-circle" style={{ color: "#ff7373" }} />
                <CountNumber>31</CountNumber>
              </IndicatorCount>
              <IndicatorCount>
                <Icon type="question-circle" style={{ color: "#84f5fb" }} />
                <CountNumber>22</CountNumber>
              </IndicatorCount>
              <IndicatorCount>
                <Icon type="minus-circle" style={{ color: "#ffa084" }} />
                <CountNumber>20</CountNumber>
              </IndicatorCount>
              <IndicatorCount>
                <Icon type="check-circle" style={{ color: "#87fdb1" }} />
                <CountNumber>209</CountNumber>
              </IndicatorCount>
            </React.Fragment>
          ) : null} */}
          {isReadyToScan ? (
            <Button type={"primary"}>Schedule Scan</Button>
          ) : null}
        </div>
      </SpacedFlex>
    </StyledCard>
  );
};
