export enum Endpoint {
  NAMESPACE = "apianalysis",
  VERSION = "v1"
}

export enum ScanStatus {
  NONE = 0,
  CRAWLING = 1,
  CRAWLING_DONE = 2,
  SCANNING = 3,
  SCANNING_DONE = 4,
  ARCHIVED = 5
}
