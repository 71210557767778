import { loginFieldConfig } from "../interfaces";

export const INPUT_FIELD_CONFIGS: Array<loginFieldConfig> = [
  {
    id: "username",
    rule: [
      {
        required: true,
        message: "Please enter a valid username"
      }
    ],
    icon: "user",
    placeholder: "Username / email",
    type: "text"
  },
  {
    id: "password",
    rule: [{ required: true, message: "Please enter your password" }],
    icon: "lock",
    placeholder: "Password",
    type: "password"
  }
];

export const SCANSTATUSTEXTS = [
  "Not Started",
  "Crawling",
  "Crawling Completed",
  "Scanning",
  "Scanning Completed",
  "Archived"
];
export const DEFAULT_PAGE_TITLE = "Application";
export const DOC_TITLE_SEPARATOR = "|";
export const APP_NAME = "API Scanner";
export const ERROR_NOTIFICATION_DURATION = 5000;
export const USER_INPUT_DEBOUNCE_TIME = 400;
export const DEFAULT_PAGE_SIZE = 10;
