import React from "react";
import { Route } from "react-router-dom";

import Login from "../containers/Login";

const LOGIN_ROUTES: Array<any> = [
  <Route
    path={["/", "/login", "/logout"]}
    exact
    key="entry-route"
    component={Login}
  />
];

export default LOGIN_ROUTES;
