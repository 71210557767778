import { takeLatest, put, call } from "redux-saga/effects";

import { authAPI } from "../api";
import { LOG_IN, LOG_OUT, REDIRECT_TO_LOGIN } from "../actionKeys";
import { toggleAuthLoader, showNavs } from "../actions/appActions";
import { resetOrgDetails } from "../actions/orgActions";
import { resetProjects } from "../actions/projectsActions";
import { resetSpaces } from "../actions/spacesActions";
import { reduxAction } from "../interfaces";
import { getOrgDetails } from "./miscellanious";
import { notifyError } from "../utils";

function* resetUserStore() {
  yield put(showNavs(false));
  yield put(resetOrgDetails());
  yield put(resetSpaces());
  yield put(resetProjects());
}

function* authenticateUser(action: reduxAction) {
  try {
    const { username, password, history } = action.payload;
    const response = yield call(authAPI.login, { username, password });
    if (response) {
      yield call(getOrgDetails);
      history.push("/dashboard");
      yield put(showNavs(true));
    }
  } catch (error) {
    yield call(notifyError, error);
  } finally {
    yield put(toggleAuthLoader(false));
  }
}

function* unauthenticateUser(action: reduxAction) {
  try {
    const { push } = action.payload;
    const response = yield call(authAPI.logout);
    if (response) {
      yield call(resetUserStore);
      push("/login");
    }
  } catch (error) {
    yield call(notifyError, error);
  } finally {
    yield put(toggleAuthLoader(false));
  }
}

function* redirectToLoginRoute(action: reduxAction) {
  yield (window.location.href = "/login");
}

export default function* authSaga() {
  yield takeLatest(LOG_IN, authenticateUser);
  yield takeLatest(LOG_OUT, unauthenticateUser);
  yield takeLatest(REDIRECT_TO_LOGIN, redirectToLoginRoute);
}
