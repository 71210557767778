import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select } from "antd";
import { USER_INPUT_DEBOUNCE_TIME } from "../../consts";
import { default as _ } from "lodash";
import { Space } from "../../interfaces";

const { Option } = Select;

const DiscoverDomainForm: any = (props: any) => {
  const {
    getFieldDecorator,
    isLoading,
    saveName,
    spaces,
    getSpacesData,
    isFormVisible,
    triggerSpaceSearch
  } = props;
  const [spaceLoading, toggleSpaceLoading] = useState(true);

  const fetchSearchResults = _.debounce((query: string): any => {
    if (query.trim().length > 1) {
      triggerSpaceSearch({ query, toggleSpaceLoading });
    }
  }, USER_INPUT_DEBOUNCE_TIME);

  const SearchHandler = (query: string) => {
    if (query) {
      toggleSpaceLoading(true);
      fetchSearchResults(query);
    }
  };

  useEffect(() => {
    if (isFormVisible) {
      toggleSpaceLoading(true);
      getSpacesData({ toggleSpaceLoading });
    }
  }, [isFormVisible, getSpacesData]);

  const renderSelectComponent = () => {
    return (
      <Select
        showSearch
        style={{ width: "100%" }}
        placeholder="Please select a space"
        loading={spaceLoading}
        onSearch={SearchHandler}
      >
        {spaces &&
          spaces.map((space: Space) => {
            return (
              <Option key={space.id} value={space.id}>
                {space.name}
              </Option>
            );
          })}
      </Select>
    );
  };

  return (
    <Form onSubmit={saveName} style={{ marginBottom: 16 }}>
      <Form.Item label="Domain / Term">
        {getFieldDecorator("query", {
          rules: [
            {
              required: true,
              message: "Please enter a valid search term / domain"
            }
          ]
        })(
          <Input
            placeholder="Please enter a domain / term"
            autoComplete={"off"}
          />
        )}
      </Form.Item>
      <Form.Item label="Space">
        {getFieldDecorator("spaceId", {
          rules: [
            {
              required: true,
              message: "Please select a space"
            }
          ]
        })(renderSelectComponent())}
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          {isLoading ? "Starting Discovery..." : "Start Discovery"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { DiscoverDomainForm };
