import { ERROR_NOTIFICATION_DURATION } from "../consts";
import { store } from "../store";
import { showNotification } from "../actions/appActions";

/*
@description: Helper to display breadcrumb text in app
@input: pathName<string>
@returns: breadCrumb Component Data <Object>
*/
export const getBreadCrumbItems = (pathName: string) => {
  const nonRootPath = pathName.substring(1);
  const crumbArray = nonRootPath.split("/");
  return crumbArray.map((path: string, index: number) => {
    return {
      key: `${path}-${index}`,
      link: `/${path}`,
      title: formatText({ text: path, delimmeter: "-" })
    };
  });
};

/*
@description: Helper to format display string
@input: options <text:string,delimeter:string,excludeLastWord:boolean>
@returns: formatted string <string>
*/
export const formatText = (opts: any): string => {
  const { text, delimmeter, excludeLastWord } = opts;
  if (text && text.trim().length) {
    const textArray = text.split(delimmeter);
    if (excludeLastWord) {
      textArray.pop();
    }
    return textArray
      .map((word: string) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  }
  return "";
};

/*
@description: Helper to notify error message sent from server on network call failures
@input: error<Object>
@returns: void
*/
export const notifyError = (error: any): void => {
  if (!error.response) {
    store.dispatch(
      showNotification({
        message: error.message,
        duration: ERROR_NOTIFICATION_DURATION
      })
    );
  }
};
