import actionGenerator from "../utils/actionGenerator";
import {
  LOG_IN,
  LOG_OUT,
  TOGGLE_LOADER,
  TOGGLE_AUTH_LOADER,
  TOGGLE_CREATE_SPACE_MODEL,
  SHOW_NOTIFICATION,
  TOGGLE_SIDEBAR_COLLAPSE,
  TOGGLE_DISCOVER_DOMAIN_MODEL,
  FETCH_DASHBOARD_STATS,
  SHOW_NAVS,
  REDIRECT_TO_LOGIN
} from "../actionKeys";

export const login = actionGenerator(LOG_IN);
export const logout = actionGenerator(LOG_OUT);
export const toggleLoader = actionGenerator(TOGGLE_LOADER);
export const toggleAuthLoader = actionGenerator(TOGGLE_AUTH_LOADER);
export const toggleCreateSpaceModel = actionGenerator(
  TOGGLE_CREATE_SPACE_MODEL
);
export const showNotification = actionGenerator(SHOW_NOTIFICATION);
export const toggleSidebarCollapse = actionGenerator(TOGGLE_SIDEBAR_COLLAPSE);
export const toggleDiscoverDomainModel = actionGenerator(
  TOGGLE_DISCOVER_DOMAIN_MODEL
);
export const fetchDashboardStats = actionGenerator(FETCH_DASHBOARD_STATS);
export const showNavs = actionGenerator(SHOW_NAVS);
export const redirectToLogin = actionGenerator(REDIRECT_TO_LOGIN);
