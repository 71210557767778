import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getInactiveSearchesBySpaceId } from "../../../actions/spacesActions";
import { DEFAULT_PAGE_SIZE } from "../../../consts";
import { Pagination } from "../../../components/Generics";
import {
  LoadingSpinner,
  SimpleNoData,
  CustomList
} from "../../../components/Generics";

const renderSearchCards = ({ searches }: any) => {
  if (searches.length === 0) {
    return <SimpleNoData />;
  }
  return <CustomList data={searches} />;
};

const SearchesList = (props: any) => {
  const { id, getSearches, inactiveSearches: searches } = props;
  const [searchesCount, setSearchesCount] = useState(0);
  const [loading, toggleLoading] = useState(true);
  const stateSetters = {
    setSearchesCount,
    toggleLoading
  };
  const [offset, setOffset] = useState(0);

  const hadlePaginationChange = (page: number, pageSize: number) => {
    toggleLoading(true);
    const calculatedOffset = (page - 1) * pageSize;
    setOffset(calculatedOffset);
  };

  useEffect(() => {
    toggleLoading(true);
    getSearches({ id, offset, stateSetters, searchType: "INACTIVE" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  return (
    <React.Fragment>
      {loading ? (
        <LoadingSpinner size={"small"} flex />
      ) : (
        renderSearchCards({
          searches
        })
      )}
      {searchesCount > DEFAULT_PAGE_SIZE ? (
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <Pagination
            defaultCurrent={1}
            total={searchesCount}
            pageSize={DEFAULT_PAGE_SIZE}
            changeHanlder={
              hadlePaginationChange ? hadlePaginationChange : () => {}
            }
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    inactiveSearches: state.spaces.inactiveSearches,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getSearches: (payload: any) => {
      dispatch(getInactiveSearchesBySpaceId(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchesList);
