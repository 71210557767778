import React, { useEffect } from "react";
import { DOC_TITLE_SEPARATOR, APP_NAME } from "../../consts";
import styled from "styled-components";
import ProjectsList from "./DetailsContainers/Projects";
import DomainsList from "./DetailsContainers/Domains";
import SearchesList from "./DetailsContainers/Searches";
import ActiveSearchesList from "./DetailsContainers/ActiveSearches";
import wrapBreadcrumb from "../../hoc/BreadCrumbWrapper";

// const DarkPanel = styled.div`
//   background: #132738;
//   padding: 1rem;
//   margin-bottom: 2rem;
//   box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.4),
//     -2px -2px 14px rgba(0, 0, 0, 0.22);
//   border-radius: 12px;
//   h3 {
//     color: #7aa5ce;
//     font-weight: bold;
//     margin-bottom: 1rem;
//     display: inline-block;
//     background: #1b3448;
//     border-radius: 6px;
//     padding: 0.2rem 0.5rem;
//   }
// `;

const Panel = styled.div`
  padding: 1rem;
  margin-bottom: 2rem;
  h3 {
    color: #7aa5ce;
    font-weight: bold;
    margin-bottom: 1rem;
    display: inline-block;
    padding: 0.2rem 0.5rem;
  }
`;

const SpaceDetail = (props: any) => {
  const { id } = props;

  useEffect(() => {
    document.title = `Spaces ${DOC_TITLE_SEPARATOR} ${APP_NAME}`;
  }, []);

  return (
    <React.Fragment>
      <Panel>
        <h3>Active Searches</h3>
        <ActiveSearchesList id={id} />
      </Panel>
      <Panel>
        <h3>Domains</h3>
        <DomainsList id={id} />
      </Panel>
      <Panel>
        <h3>Projects</h3>
        <ProjectsList id={id} />
      </Panel>
      <Panel>
        <h3>Search History</h3>
        <SearchesList id={id} />
      </Panel>
    </React.Fragment>
  );
};

export default wrapBreadcrumb()(SpaceDetail);
