import React from "react";
import styled from "styled-components";

const PaddedDiv = styled.div`
  padding: 24px;
  min-height: 360px;
`;

const FlexPanel = styled(PaddedDiv)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 9px;
`;

const definePanelComponet = (props: any) => {
  const { isFlex, children } = props;
  if (isFlex) {
    return <FlexPanel>{children}</FlexPanel>;
  }
  return <PaddedDiv>{children}</PaddedDiv>;
};

const Panel = (props: any) => {
  const PanelComponent = definePanelComponet(props);
  return <Wrapper>{PanelComponent}</Wrapper>;
};

export default Panel;
