import React from "react";
import { Typography, Button } from "antd";
import styled from "styled-components";

const noDataImage = require("../../images/no_data.png");

const NoDataText = styled(Typography.Text)`
  margin-top: 1em;
  text-align: center;
`;

const ActionButton = styled(Button)`
  display: block;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
`;

const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoData = (props: any) => {
  const {
    image,
    imageWidth,
    imageHeight,
    text,
    actionMethod,
    actionText
  } = props;
  const defaultImageWidth = 96;
  const defaultImageHeight = 63;
  const defaultText = "No Data";
  const defaultActionText = "Create New";

  const NoDataImage = styled.img`
    width: ${imageWidth || defaultImageWidth}px;
    height: ${imageHeight || defaultImageHeight}px;
  `;

  return (
    <NoDataWrapper>
      <NoDataImage src={image || noDataImage} alt="nodata" />
      <NoDataText>{text || defaultText}</NoDataText>
      {actionMethod ? (
        <ActionButton
          type="primary"
          icon="plus"
          size="large"
          shape="round"
          onClick={actionMethod.bind(null, true)}
        >
          {actionText || defaultActionText}
        </ActionButton>
      ) : null}
    </NoDataWrapper>
  );
};

export default NoData;
