import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Alert } from "antd";
import styled from "styled-components";

import { Switch, BrowserRouter as Router } from "react-router-dom";

import SideNav from "../../components/SideNav";
import HeaderNav from "../../components/HeaderNav";
import AppRoutes from "../../routes";
import {
  showNotification,
  toggleSidebarCollapse
} from "../../actions/appActions";

const { Content } = Layout;

const CustomLayout = styled(Layout)`
  min-height: 100vh;
`;

const MainContainer = styled(Content)`
  background: #1a3146;
  color: #7aa5ce;
`;

const Notifier = styled(Alert)`
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
  z-index: 9999999999;
`;

const App: React.FC = (props: any) => {
  const {
    notification,
    resetNotification,
    collapsed,
    collapseSidebar,
    showNav
  } = props;

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        resetNotification();
      }, notification.duration);
    }
  }, [notification, resetNotification]);

  const toggleCollapsed = () => {
    collapseSidebar(!collapsed);
  };

  const getContainerStyle = () => {
    const styleObj = {
      padding: "0",
      marginTop: 0
    };
    if (showNav) {
      styleObj.padding = "0 16px";
      styleObj.marginTop = 64;
    }
    return styleObj;
  };

  return (
    <React.Fragment>
      <Router>
        <CustomLayout>
          <SideNav collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
          <Layout
            style={{
              marginLeft: showNav ? (collapsed ? 80 : 200) : 0
            }}
          >
            <HeaderNav />
            <MainContainer style={getContainerStyle()}>
              {notification ? (
                <Notifier
                  message={notification.message}
                  type="error"
                  showIcon
                />
              ) : null}
              <Switch>{AppRoutes}</Switch>
            </MainContainer>
          </Layout>
        </CustomLayout>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    notification: state.app.notification,
    collapsed: state.app.sidebarCollapsed,
    showNav: state.app.showNavs
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetNotification: () => {
      dispatch(showNotification(null));
    },
    collapseSidebar: (flag: boolean) => {
      dispatch(toggleSidebarCollapse(flag));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
