import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form, Alert } from "antd";
import styled from "styled-components";
import { DOC_TITLE_SEPARATOR, APP_NAME } from "../../consts";

import {
  LoginWrapper,
  LoginContainer,
  LoginForm,
  LoginTitle
} from "../../components/Login";
import { login, toggleAuthLoader } from "../../actions/appActions";
import { authCredentials } from "../../interfaces/auth";
import { showNotification, showNavs } from "../../actions/appActions";

const Notifier = styled(Alert)`
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
  z-index: 999;
`;

const LoginComponent: React.FC = (props: any) => {
  const {
    form,
    performLogin,
    history,
    isLoading,
    notification,
    resetNotification,
    hideNavBars
  } = props;
  const { getFieldDecorator, validateFields } = form;

  useEffect(() => {
    document.title = `Login ${DOC_TITLE_SEPARATOR} ${APP_NAME}`;
    hideNavBars();
    if (notification) {
      setTimeout(() => {
        resetNotification();
      }, notification.duration);
    }
  }, [notification, resetNotification, hideNavBars]);

  const handleLogin = (e: any) => {
    e.preventDefault();
    validateFields((error: any, values: object) => {
      if (!error) {
        performLogin({ ...values, history });
      }
    });
  };

  return (
    <React.Fragment>
      <LoginWrapper>
        {notification ? (
          <Notifier message={notification.message} type="error" showIcon />
        ) : null}
        <LoginContainer>
          <LoginTitle>{"API Scanner"}</LoginTitle>
          <LoginForm
            getFieldDecorator={getFieldDecorator}
            submitHandler={handleLogin}
            isLoading={isLoading}
          />
        </LoginContainer>
      </LoginWrapper>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    isLoading: state.app.showAuthLoader,
    notification: state.app.notification,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    performLogin: (credentials: authCredentials) => {
      dispatch(toggleAuthLoader(true));
      dispatch(login(credentials));
    },
    resetNotification: () => {
      dispatch(showNotification(null));
    },
    hideNavBars: () => {
      dispatch(showNavs(false));
    }
  };
};

const WrappedLoginForm = Form.create({ name: "main_login_form" })(
  connect(mapStateToProps, mapDispatchToProps)(LoginComponent)
);

export default WrappedLoginForm;
