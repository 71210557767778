import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Breadcrumb } from "../components/Generics";
import { getBreadCrumbItems } from "../utils";

const WrapBreadCrumbs = (props?: any): Function => {
  return (ContentComponent: React.FC): React.FC => {
    const ResultantComponent: React.FC = () => {
      const params: any = useParams();
      const { id } = params;
      const { pathname } = useLocation();
      return (
        <React.Fragment>
          {pathname ? (
            <Breadcrumb items={getBreadCrumbItems(pathname)} />
          ) : null}
          <ContentComponent {...props} id={+id} />
        </React.Fragment>
      );
    };
    return ResultantComponent;
  };
};

export default WrapBreadCrumbs;
