const actionGenerator: Function = (type: string) => {
  return (payload: any) => {
    return {
      type,
      payload
    };
  };
};

export default actionGenerator;
