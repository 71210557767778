import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getDomainsBySpaceId } from "../../../actions/spacesActions";
import { DEFAULT_PAGE_SIZE } from "../../../consts";
import { Pagination } from "../../../components/Generics";
import { useHistory } from "react-router-dom";
import {
  LoadingSpinner,
  SimpleNoData,
  CustomList
} from "../../../components/Generics";

const renderDomainsList = ({ domains }: any) => {
  if (domains.length === 0) {
    return <SimpleNoData />;
  }
  return <CustomList data={domains} />;
};

const DomainsList = (props: any) => {
  const { id, getDomains } = props;
  const [domains, setDomains] = useState([]);
  const [domainsCount, setDomainsCount] = useState(0);
  const [loading, toggleLoading] = useState(true);
  const stateSetters = {
    setDomains,
    setDomainsCount,
    toggleLoading
  };
  const [offset, setOffset] = useState(0);

  const hadlePaginationChange = (page: number, pageSize: number) => {
    const calculatedOffset = (page - 1) * pageSize;
    setOffset(calculatedOffset);
  };

  useEffect(() => {
    toggleLoading(true);
    getDomains({ id, offset, stateSetters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDomains, offset]);

  const history = useHistory();

  const handleCardClick = () => {
    history.push(`/dashboard`);
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingSpinner size={"small"} flex />
      ) : (
        renderDomainsList({
          domains,
          handleCardClick
        })
      )}
      {domainsCount > DEFAULT_PAGE_SIZE ? (
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <Pagination
            defaultCurrent={1}
            total={domainsCount}
            pageSize={DEFAULT_PAGE_SIZE}
            changeHanlder={
              hadlePaginationChange ? hadlePaginationChange : () => {}
            }
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDomains: (payload: any) => {
      dispatch(getDomainsBySpaceId(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainsList);
