import React from "react";
import { Route } from "react-router-dom";

import { SpaceList, SpaceDetail } from "../containers/Spaces";
import Dashboard from "../containers/Dashboard";
import Projects from "../containers/Projects";
import ProjectDetals from "../containers/Projects/ProjectDetails";

const DASHBOARD_ROUTES: Array<any> = [
  <Route
    path={"/dashboard"}
    exact
    key="dashboard-route"
    component={Dashboard}
  />,
  <Route path={"/spaces"} exact key="spaces-route" component={SpaceList} />,
  <Route
    path={"/spaces/:id"}
    exact
    key="space-detail-route"
    component={SpaceDetail}
  />,
  <Route path={"/projects"} exact key="projects-route" component={Projects} />,
  <Route
    path={"/projects/:id"}
    exact
    key="projects-detail-route"
    component={ProjectDetals}
  />
];

export default DASHBOARD_ROUTES;
