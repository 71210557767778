import { takeLatest, put, call } from "redux-saga/effects";
import { spacesAPI, orgAPI } from "../api";
import {
  ASSOSCIATE_QUERY_TO_SPACE,
  GET_PROJECTS_BY_SPACE_ID,
  GET_DOMAINS_BY_SPACE_ID,
  GET_ACTIVE_SEARCHES_BY_SPACE_ID,
  GET_INACTIVE_SEARCHES_BY_SPACE_ID,
  CREATE_SPACE
} from "../actionKeys";
import { reduxAction } from "../interfaces";
import { notifyError } from "../utils";
import { getOrgId } from "./miscellanious";
import {
  setActiveSearches,
  setInactiveSearches
} from "../actions/spacesActions";

function* createNewSpace(action: reduxAction) {
  const { name, removeUIBlockers, history } = action.payload;
  try {
    const orgId = yield call(getOrgId);
    if (orgId) {
      const response = yield call(orgAPI.creatOrgSpace, orgId, name);
      yield call(removeUIBlockers);
      if (response) {
        const {
          data: { id }
        } = response;
        history.push(`/spaces/${id}`);
      }
    }
  } catch (error) {
    yield call(notifyError, error);
  }
}

function* associateQueryWithSpace(action: reduxAction) {
  const { spaceId: id, query, history, removeUIBlockers } = action.payload;
  try {
    const response = yield call(spacesAPI.setSpaceSearchEntity, { id, query });
    yield call(removeUIBlockers);
    if (response) {
      history.push(`/spaces/${id}`);
    }
  } catch (error) {
    yield call(notifyError, error);
  }
}

function* getProjectsBySpaceId(action: reduxAction) {
  const { id, offset, limit, stateSetters } = action.payload;
  const { setProjects, setProjectsCount, toggleLoading } = stateSetters;
  try {
    const response = yield call(spacesAPI.getSpaceProjects, {
      id,
      offset,
      limit
    });
    const { data } = response;
    if (data && data.count) {
      const { count, results } = data;
      yield call(setProjects, results);
      yield call(setProjectsCount, count);
    } else {
      yield call(setProjects, []);
      yield call(setProjectsCount, 0);
    }
  } catch (error) {
    yield call(notifyError, error);
  } finally {
    yield call(toggleLoading, false);
  }
}

function* getDomainsBySpaceId(action: reduxAction) {
  const { id, offset, limit, stateSetters } = action.payload;
  const { setDomains, setDomainsCount, toggleLoading } = stateSetters;
  try {
    const response = yield call(spacesAPI.getSpaceDomains, {
      id,
      offset,
      limit
    });
    const { data } = response;
    if (data && data.count) {
      const { count, results } = data;
      yield call(setDomains, results);
      yield call(setDomainsCount, count);
    } else {
      yield call(setDomains, []);
      yield call(setDomainsCount, 0);
    }
  } catch (error) {
    yield call(notifyError, error);
  } finally {
    yield call(toggleLoading, false);
  }
}

function* getActiveSearchesBySpaceId(action: reduxAction) {
  const { id, offset, limit, stateSetters, searchType } = action.payload;
  const { setSearchesCount, toggleLoading } = stateSetters;
  try {
    const response = yield call(spacesAPI.getSpaceSearches, {
      id,
      offset,
      limit,
      searchType
    });
    const { data } = response;
    if (data && data.count) {
      const { count, results } = data;
      const activeSearches = results.filter((search: any) => {
        return search.progress < 100;
      });
      yield put(setActiveSearches(activeSearches));
      yield call(setSearchesCount, count);
    } else {
      yield put(setActiveSearches([]));
      yield call(setSearchesCount, 0);
    }
  } catch (error) {
    yield call(notifyError, error);
  } finally {
    yield call(toggleLoading, false);
  }
}

function* getInactiveSearchesBySpaceId(action: reduxAction) {
  const { id, offset, limit, stateSetters, searchType } = action.payload;
  const { setSearchesCount, toggleLoading } = stateSetters;
  try {
    const response = yield call(spacesAPI.getSpaceSearches, {
      id,
      offset,
      limit,
      searchType
    });
    const { data } = response;
    if (data && data.count) {
      const { count, results } = data;
      const inactiveSearches = results.filter((search: any) => {
        return search.progress === 100;
      });
      yield put(setInactiveSearches(inactiveSearches));
      yield call(setSearchesCount, count);
    } else {
      yield put(setInactiveSearches([]));
      yield call(setSearchesCount, 0);
    }
  } catch (error) {
    yield call(notifyError, error);
  } finally {
    yield call(toggleLoading, false);
  }
}

export default function* spacesSaga() {
  yield takeLatest(ASSOSCIATE_QUERY_TO_SPACE, associateQueryWithSpace);
  yield takeLatest(GET_PROJECTS_BY_SPACE_ID, getProjectsBySpaceId);
  yield takeLatest(GET_DOMAINS_BY_SPACE_ID, getDomainsBySpaceId);
  yield takeLatest(GET_ACTIVE_SEARCHES_BY_SPACE_ID, getActiveSearchesBySpaceId);
  yield takeLatest(
    GET_INACTIVE_SEARCHES_BY_SPACE_ID,
    getInactiveSearchesBySpaceId
  );
  yield takeLatest(CREATE_SPACE, createNewSpace);
}
