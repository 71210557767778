import {
  TOGGLE_LOADER,
  TOGGLE_CREATE_SPACE_MODEL,
  TOGGLE_AUTH_LOADER,
  SHOW_NOTIFICATION,
  TOGGLE_SIDEBAR_COLLAPSE,
  TOGGLE_DISCOVER_DOMAIN_MODEL,
  SHOW_NAVS
} from "../actionKeys";

import { appState, reduxAction } from "../interfaces";

const initialAppState: appState = {
  loading: false,
  showNavs: false,
  showAuthLoader: false,
  createSpaceModalVisible: false,
  showDiscoverDomainModal: false,
  notification: null,
  sidebarCollapsed: false
};

const app = (state: appState = initialAppState, action: reduxAction) => {
  switch (action.type) {
    case TOGGLE_LOADER:
      return { ...state, loading: action.payload };
    case SHOW_NAVS:
      return { ...state, showNavs: action.payload };
    case TOGGLE_CREATE_SPACE_MODEL:
      return { ...state, createSpaceModalVisible: action.payload };
    case TOGGLE_AUTH_LOADER:
      return { ...state, showAuthLoader: action.payload };
    case SHOW_NOTIFICATION:
      return { ...state, notification: action.payload };
    case TOGGLE_SIDEBAR_COLLAPSE:
      return { ...state, sidebarCollapsed: action.payload };
    case TOGGLE_DISCOVER_DOMAIN_MODEL:
      return { ...state, showDiscoverDomainModal: action.payload };
    default:
      return { ...state };
  }
};

export default app;
