import {
  SET_SPACES_DATA,
  SET_SPACES_COUNT,
  RESET_SPACES,
  SET_INACTIVE_SEARCHES,
  SET_ACTIVE_SEARCHES
} from "../actionKeys";
import { SpaceStateTree, reduxAction } from "../interfaces";

const initialState: SpaceStateTree = {
  count: 0,
  list: [],
  activeSearches: [],
  inactiveSearches: []
};

const spaces = (state: SpaceStateTree = initialState, action: reduxAction) => {
  switch (action.type) {
    case SET_SPACES_DATA:
      return { ...state, list: [...action.payload] };
    case SET_SPACES_COUNT:
      return { ...state, count: action.payload };
    case SET_INACTIVE_SEARCHES:
      return { ...state, inactiveSearches: action.payload };
    case SET_ACTIVE_SEARCHES:
      return { ...state, activeSearches: action.payload };
    case RESET_SPACES:
      return { ...initialState };
    default:
      return state;
  }
};

export default spaces;
