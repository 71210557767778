import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form } from "antd";
import { toggleDiscoverDomainModel } from "../../actions/appActions";
import {
  associateQueryToSpace,
  searchSpaceByName
} from "../../actions/spacesActions";
import { DiscoverDomainForm } from "./DiscoverDomainForm";
import { CustomizedModal } from "./ModalStyles";

const ModalComponent: any = (props: any) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const {
    visible,
    toggleVisibility,
    form,
    spaces,
    getSpacesData,
    tagQueryToSpace,
    triggerSpaceSearch
  } = props;
  const { getFieldDecorator, validateFields, resetFields } = form;
  const history = useHistory();

  const removeUIBlockers = () => {
    setConfirmLoading(false);
    resetFields();
    toggleVisibility(false);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    validateFields((error: any, values: object) => {
      if (!error) {
        setConfirmLoading(true);
        tagQueryToSpace({ ...values, removeUIBlockers, history });
      }
    });
  };

  const handleModalClose = () => {
    if (confirmLoading) {
      return;
    }
    toggleVisibility(false);
  };

  return (
    <CustomizedModal
      title="Discover Domain"
      visible={visible}
      onOk={handleSave}
      centered
      onCancel={handleModalClose}
      maskClosable={false}
      footer={null}
    >
      <DiscoverDomainForm
        getFieldDecorator={getFieldDecorator}
        isLoading={confirmLoading}
        getSpacesData={getSpacesData}
        saveName={handleSave}
        spaces={spaces}
        isFormVisible={visible}
        triggerSpaceSearch={triggerSpaceSearch}
      />
    </CustomizedModal>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    visible: state.app.showDiscoverDomainModal,
    spaces: state.spaces.list,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleVisibility: (flag: boolean) => {
      dispatch(toggleDiscoverDomainModel(flag));
    },
    tagQueryToSpace: (payload: any) => {
      dispatch(associateQueryToSpace(payload));
    },
    triggerSpaceSearch: (payload: any) => {
      dispatch(searchSpaceByName(payload));
    }
  };
};

const WrappedModalComponent: any = Form.create({
  name: "discover_domain_form"
})(connect(mapStateToProps, mapDispatchToProps)(ModalComponent));

export default WrappedModalComponent;
