//App Related Actions
export const LOG_IN: string = "LOG_IN";
export const LOG_OUT: string = "LOG_OUT";
export const TOGGLE_LOADER: string = "TOGGLE_LOADER";
export const TOGGLE_CREATE_SPACE_MODEL: string = "TOGGLE_CREATE_SPACE_MODEL";
export const TOGGLE_AUTH_LOADER: string = "TOGGLE_AUTH_LOADER";
export const SHOW_NOTIFICATION: string = "SHOW_NOTIFICATION";
export const TOGGLE_SIDEBAR_COLLAPSE: string = "TOGGLE_SIDEBAR_COLLAPSE";
export const TOGGLE_DISCOVER_DOMAIN_MODEL: string =
  "TOGGLE_DISCOVER_DOMAIN_MODEL";
export const FETCH_DASHBOARD_STATS = "FETCH_DASHBOARD_STATS";
export const SHOW_NAVS = "SHOW_NAVS";
export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";
