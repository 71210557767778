import styled from "styled-components";
import { Modal } from "antd";

export const CustomizedModal = styled(Modal)`
  .ant-modal-content {
    background: #31465a;
    .ant-modal-close {
      color: rgb(121, 165, 207);
    }
    .ant-modal-header {
      .ant-modal-title {
        color: rgb(121, 165, 207);
      }
      background: rgb(0, 22, 42);
    }
    .ant-form-item-label > label {
      color: rgb(121, 165, 207);
    }
  }
`;
