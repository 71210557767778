import React from "react";
import styled from "styled-components";
import { Card, Typography, Button } from "antd";
import { default as moment } from "moment";

interface InfoCardProps {
  id: number;
  title?: string;
  createdOn: string;
  infoType: "domain" | "search";
  domainsCount?: number;
  projectsCount?: number;
  buttonClickHanlder?: (event: any) => void;
  cardClickHandler?: () => void;
  status?: number;
  hasHeader?: boolean;
}

export const StyledCard = styled(Card)`
  cursor: pointer;
  width: 100%;
  border: 1px solid #2773be82;
  background: #31465a;
  margin-bottom: 1rem;
  border-radius: 9px;
  transition: all 0.1s cubic-bezier(0.47, 0, 0.75, 0.72);
  transition-property: box-shadow, background, scale, border;
  p,
  span,
  .ant-typography {
    color: rgb(121, 165, 207);
  }
  .ant-card-head {
    border-bottom: 1px solid #1a3246;
  }
  .ant-tag {
    color: #00162a;
  }
  button.ant-btn span {
    color: #fff;
  }
  &:hover,
  &:focus {
    transform: scale(1.01);
    box-shadow: 0px 2px 0px rgba(45, 93, 142, 0.36);
    background: #2c4154;
    border-color: #1890ff;
  }
`;

export const SpacedFlex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const InfoCard = (props: InfoCardProps) => {
  const { createdOn, hasHeader, title, infoType, cardClickHandler } = props;
  let CardHeader = null;

  if (hasHeader && title) {
    CardHeader = (
      <SpacedFlex>
        <Typography.Title level={4} ellipsis>
          {title}
        </Typography.Title>
        <div style={{ display: "flex" }}>
          {createdOn ? (
            <React.Fragment>
              <div>
                <p>Created On</p>
              </div>
              <div style={{ marginLeft: 4 }}>
                <p>{moment(createdOn).format("DD-MMM-YYYY")}</p>
              </div>
            </React.Fragment>
          ) : (
            <div style={{ minWidth: 195 }} />
          )}
        </div>
      </SpacedFlex>
    );
  }

  switch (infoType) {
    case "domain":
      const { projectsCount } = props;
      return (
        <StyledCard title={CardHeader} onClick={cardClickHandler}>
          <SpacedFlex>
            <p>
              <strong>{projectsCount ? projectsCount : 0}</strong> Project(s)
            </p>
            {projectsCount && projectsCount > 0 ? (
              <Button type={"primary"}>{"View Project"}</Button>
            ) : (
              <Button type={"primary"}>{"Create Project"}</Button>
            )}
          </SpacedFlex>
        </StyledCard>
      );
    case "search":
      return (
        <StyledCard onClick={cardClickHandler}>
          <SpacedFlex>
            <div>
              <Typography.Text ellipsis>{title}</Typography.Text>
            </div>
            <div>
              <span>Created On: </span>
              <span>{moment(createdOn).format("DD-MMM-YYYY")}</span>
            </div>
          </SpacedFlex>
        </StyledCard>
      );
    default:
      return null;
  }
};

export default InfoCard;
