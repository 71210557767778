import { all } from "redux-saga/effects";

import authSaga from "./authSaga";
import spacesSaga from "./spacesSaga";
import dashboardSaga from "./dashboardSaga";
import organizationSaga from "./organizationSaga";
import domainsSaga from "./domainsSaga";
import projectsSaga from "./projectsSaga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    spacesSaga(),
    dashboardSaga(),
    organizationSaga(),
    domainsSaga(),
    projectsSaga()
  ]);
}
