import httpClient from "./axiosBase";

import { authCredentials, authAPI } from "../interfaces/auth";

const auth: authAPI = {
  async login(credentials: authCredentials) {
    return await httpClient.post("/login", { ...credentials });
  },
  async logout() {
    return await httpClient.post("/logout");
  }
};

export default auth;
