import httpClient from "./axiosBase";
import { OrgAPI } from "../interfaces/organizations";
import { orgProjectsRequestParam } from "../interfaces/organizations";

const baseUrl = "/organizations";

const organizations: OrgAPI = {
  async getOrganizations() {
    return await httpClient.get(baseUrl);
  },
  async getDashboardStats(orgId: number) {
    return await httpClient.get(`${baseUrl}/${orgId}/overview`);
  },
  async getAllSpaces(orgId: number, offset?: number, limit?: number) {
    return await httpClient.get(`${baseUrl}/${orgId}/spaces`, {
      params: { offset, limit }
    });
  },
  async searchSpaces(orgId: number, query: string) {
    return await httpClient.get(`${baseUrl}/${orgId}/spaces`, {
      params: { q: encodeURIComponent(query) }
    });
  },
  async getOrgProjects(params: orgProjectsRequestParam) {
    const { orgId, limit, offset, archived } = params;
    return await httpClient.get(`${baseUrl}/${orgId}/projects`, {
      params: { limit, offset, archived }
    });
  },
  async creatOrgSpace(orgId: number, name: string) {
    return await httpClient.post(`${baseUrl}/${orgId}/spaces`, {
      name
    });
  }
};

export default organizations;
