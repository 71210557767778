import {
  SET_PROJECTS,
  RESET_PROJECTS,
  SET_PROJECTS_COUNT
} from "../actionKeys";
import { ProjectState, reduxAction } from "../interfaces";

const initialProjectsState: ProjectState = {
  count: 0,
  list: []
};

const projects = (
  state: ProjectState = initialProjectsState,
  action: reduxAction
) => {
  switch (action.type) {
    case SET_PROJECTS_COUNT:
      return { ...state, count: action.payload };
    case SET_PROJECTS:
      return { ...state, list: [...action.payload] };
    case RESET_PROJECTS:
      return { count: 0, list: [] };
    default:
      return state;
  }
};

export default projects;
