import React from "react";
import { Typography, Badge } from "antd";
import styled from "styled-components";

const ProjectsWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  h4 {
    color: #7aa5ce;
    display: inline-block;
    min-width: 100px;
  }
  @media (max-width: 954px) {
    flex-direction: column;
  }
`;

// const FilterWrapper = styled.div`
//   display: flex;
//   margin-left: 2rem;
//   min-width: 90%;
//   justify-content: space-evenly;
//   @media (max-width: 769px) {
//     flex-direction: column;
//   }
// `;

// const FilterItem = styled.button`
//   margin: 1rem;
//   position: relative;
//   border: 1px solid #79a5cf;
//   background: transparent;
//   padding: 4px 8px;
//   border-radius: 12px;
//   cursor: pointer;
//   >span: first-child {
//     position: absolute;
//     left: 2px;
//   }
//   > span:last-child {
//     color: #7aa5ce;
//     padding-left: 2.5rem;
//   }
//   &.active,
//   &:hover,
//   &:focus,
//   &:active {
//     background: #639ace82;
//     transition: all 0.2s ease-in;
//     outline: none;
//   }
//   &.active,
//   &:active {
//     border-color: #1890ff;
//   }
// `;

const ProjectCount = styled(Badge)`
  margin: 0.4rem;
`;

export const ProjectsFilter = (props: any) => {
  const { projectsCount } = props;
  return (
    <ProjectsWrapper>
      <Typography.Title level={4} ellipsis>
        Projects
        <ProjectCount
          count={projectsCount}
          overflowCount={10000}
          showZero
          style={{ backgroundColor: "#285E91" }}
        />
      </Typography.Title>
      {/* <FilterWrapper>
        <FilterItem className={"active"}>
          <Badge count={125} showZero style={{ backgroundColor: "#52c41a" }} />
          <Typography.Text>ALL</Typography.Text>
        </FilterItem>
        <FilterItem>
          <Badge
            count={5}
            showZero
            style={{ backgroundColor: "rgb(42, 155, 210)" }}
          />
          <Typography.Text>SCANNING</Typography.Text>
        </FilterItem>
        <FilterItem>
          <Badge
            count={120}
            showZero
            style={{ backgroundColor: "rgb(130, 132, 11)" }}
          />
          <Typography.Text>NOT SCANNED</Typography.Text>
        </FilterItem>
        <FilterItem>
          <Badge count={4} showZero />
          <Typography.Text>REJECTED</Typography.Text>
        </FilterItem>
      </FilterWrapper> */}
    </ProjectsWrapper>
  );
};
