import React from "react";
import styled from "styled-components";

const NoDataDiv = styled.div`
  text-align: center;
  margin: 1rem 0;
`;

const SimpleNoData = () => {
  return <NoDataDiv>No Data Available</NoDataDiv>;
};

export default SimpleNoData;
