import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { createSpace } from "../../actions/spacesActions";
import { toggleCreateSpaceModel } from "../../actions/appActions";
import styled from "styled-components";
import { CustomizedModal } from "./ModalStyles";

const SubmitButton = styled(Button)`
  float: right;
`;

const ProjectNameForm: any = (props: any) => {
  const { getFieldDecorator, isLoading, saveName } = props;

  return (
    <Form onSubmit={saveName} style={{ marginBottom: 16 }}>
      <Form.Item label="Space Name">
        {getFieldDecorator("name", {
          rules: [
            {
              required: true,
              message: "Please enter a valid name"
            }
          ]
        })(
          <Input
            placeholder="Please enter a name for the space"
            autoComplete={"off"}
          />
        )}
      </Form.Item>
      <Form.Item>
        <SubmitButton type="primary" htmlType="submit" loading={isLoading}>
          {isLoading ? "Creating..." : "Create"}
        </SubmitButton>
      </Form.Item>
    </Form>
  );
};

const CreateModal: any = (props: any) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { visible, toggleVisibility, form, createNewSpace } = props;
  const { getFieldDecorator, validateFields, resetFields } = form;
  const history = useHistory();

  const cretaeSpace = ({ name, removeUIBlockers, history }: any) => {
    createNewSpace({ name, removeUIBlockers, history });
  };

  const removeUIBlockers = () => {
    setConfirmLoading(false);
    resetFields();
    toggleVisibility(false);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    validateFields((error: any, values: object) => {
      if (!error) {
        setConfirmLoading(true);
        cretaeSpace({ ...values, removeUIBlockers, history });
      }
    });
  };

  const handleModalClose = () => {
    if (confirmLoading) {
      return;
    }
    toggleVisibility(false);
  };

  return (
    <CustomizedModal
      title="Create New Space"
      visible={visible}
      onOk={handleSave}
      centered
      onCancel={handleModalClose}
      maskClosable={false}
      footer={null}
    >
      <ProjectNameForm
        getFieldDecorator={getFieldDecorator}
        isLoading={confirmLoading}
        saveName={handleSave}
      />
    </CustomizedModal>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    visible: state.app.createSpaceModalVisible,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    createNewSpace: (payload: any) => {
      dispatch(createSpace(payload));
    },
    toggleVisibility: (flag: boolean) => {
      dispatch(toggleCreateSpaceModel(flag));
    }
  };
};

const WrappedCreateModal: any = Form.create({ name: "project_nme_modal_form" })(
  connect(mapStateToProps, mapDispatchToProps)(CreateModal)
);

export default WrappedCreateModal;
