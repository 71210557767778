import actionGenerator from "../utils/actionGenerator";
import {
  ASSOSCIATE_QUERY_TO_SPACE,
  SEARCH_SPACE_BY_NAME,
  SET_SPACES_DATA,
  SET_SPACES_COUNT,
  RESET_SPACES,
  GET_PROJECTS_BY_SPACE_ID,
  GET_DOMAINS_BY_SPACE_ID,
  GET_INACTIVE_SEARCHES_BY_SPACE_ID,
  GET_ACTIVE_SEARCHES_BY_SPACE_ID,
  CREATE_SPACE,
  SET_ACTIVE_SEARCHES,
  SET_INACTIVE_SEARCHES
} from "../actionKeys";

export const associateQueryToSpace = actionGenerator(ASSOSCIATE_QUERY_TO_SPACE);
export const searchSpaceByName = actionGenerator(SEARCH_SPACE_BY_NAME);
export const setSpacesData = actionGenerator(SET_SPACES_DATA);
export const setSpacesCount = actionGenerator(SET_SPACES_COUNT);
export const resetSpaces = actionGenerator(RESET_SPACES);
export const getProjectsBySpaceId = actionGenerator(GET_PROJECTS_BY_SPACE_ID);
export const getDomainsBySpaceId = actionGenerator(GET_DOMAINS_BY_SPACE_ID);
export const getActiveSearchesBySpaceId = actionGenerator(
  GET_ACTIVE_SEARCHES_BY_SPACE_ID
);
export const getInactiveSearchesBySpaceId = actionGenerator(
  GET_INACTIVE_SEARCHES_BY_SPACE_ID
);
export const createSpace = actionGenerator(CREATE_SPACE);
export const setActiveSearches = actionGenerator(SET_ACTIVE_SEARCHES);
export const setInactiveSearches = actionGenerator(SET_INACTIVE_SEARCHES);
