import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Icon } from "antd";
import styled from "styled-components";

const MENU_ITEMS = [
  {
    id: 1,
    key: "/dashboard",
    title: "Dashboard",
    icon: "dashboard",
    link: "/dashboard"
  },
  {
    id: 2,
    key: "/spaces",
    title: "Spaces",
    icon: "solution",
    link: "/spaces"
  },
  // {
  //   id: 3,
  //   key: "/domains",
  //   title: "Domains",
  //   icon: "global",
  //   link: "/domains"
  // },
  {
    id: 4,
    key: "/projects",
    title: "Projects",
    icon: "project",
    link: "/projects"
  }
];

const BOTTOM_MENU_ITEMS = [
  {
    id: 5,
    key: "/organization-settings",
    title: "Organization Settings",
    icon: "shop",
    link: "/organization-settings"
  },
  {
    id: 6,
    key: "/account-settings",
    title: "Account Settings",
    icon: "setting",
    link: "/account-settings"
  }
];

const StyledMenu = styled(Menu)`
  margin-top: -0.25rem;
  height: 100vh;
`;

const SideMenuGroup = styled(Menu.ItemGroup)`
  .ant-menu-item-group-title {
    padding: 0;
  }
`;

const BottomMenuGroup = styled(SideMenuGroup)`
  position: fixed;
  bottom: 48px;
`;

const MenuComponent = (props: any) => {
  const location = useLocation();
  const currentPath = `/${location.pathname.split("/")[1]}`;

  return (
    <React.Fragment>
      <StyledMenu
        theme="dark"
        defaultSelectedKeys={[currentPath]}
        selectedKeys={[currentPath]}
        mode="inline"
      >
        <SideMenuGroup>
          {MENU_ITEMS.map(menuItem => {
            return (
              <Menu.Item key={menuItem.key}>
                <Link to={menuItem.link}>
                  <Icon type={menuItem.icon} />
                  <span>{menuItem.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
        </SideMenuGroup>
        <BottomMenuGroup>
          {BOTTOM_MENU_ITEMS.map(menuItem => {
            return (
              <Menu.Item key={menuItem.key}>
                <Link to={menuItem.link}>
                  <Icon type={menuItem.icon} />
                  <span>{menuItem.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
        </BottomMenuGroup>
      </StyledMenu>
    </React.Fragment>
  );
};

export default MenuComponent;
