import httpClient from "./axiosBase";
import { DomainsAPI } from "../interfaces/domains";

const baseUrl = "/domains";

const domains: DomainsAPI = {
  async getDomainDetail(domainId: number) {
    return await httpClient.get(`${baseUrl}/${domainId}`);
  }
};

export default domains;
