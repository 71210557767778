import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import styled from "styled-components";

const StyledCrumbWrapper = styled(Breadcrumb)`
  margin: 16px 0;
  display: inline-block;
  color: rgb(121, 165, 207);
  .ant-breadcrumb-separator {
    color: rgba(121, 165, 207, 0.45);
  }
  span.ant-breadcrumb-link {
    color: rgba(121, 165, 207, 1);
    a {
      color: rgba(121, 165, 207, 0.65);
      &:hover {
        color: rgba(24, 144, 255, 0.65);
      }
    }
  }
`;

const BreadCrumbComponent = (props: any) => {
  const { items } = props;
  return (
    <StyledCrumbWrapper>
      {items.map((crumb: any, index: number) => {
        if (index === items.length - 1) {
          return (
            <Breadcrumb.Item key={crumb.key}>{crumb.title}</Breadcrumb.Item>
          );
        }
        return (
          <Breadcrumb.Item key={crumb.key}>
            <Link key={crumb.key} to={crumb.link}>
              {crumb.title}
            </Link>
          </Breadcrumb.Item>
        );
      })}
    </StyledCrumbWrapper>
  );
};

export default BreadCrumbComponent;
