import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, initSaga } from "./store";

import App from "./containers/App";

initSaga();

const APPLICATION_ENTRY = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(APPLICATION_ENTRY, document.getElementById("root"));
