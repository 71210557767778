import httpClient from "./axiosBase";
import { ProjectAPI } from "../interfaces/projects";

const baseUrl = "/projects";

const projects: ProjectAPI = {
  async getDetailsById(projectId: number) {
    return await httpClient.get(`${baseUrl}/${projectId}`);
  },
  async getProjectLinks(projectId: number) {
    return await httpClient.get(`${baseUrl}/${projectId}/links`);
  }
};

export default projects;
