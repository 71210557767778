import { SET_ORG_DETAILS, RESET_ORG_DETAILS } from "../actionKeys";
import { currentOrganization, reduxAction } from "../interfaces";

const OrgInitState: currentOrganization = {};

const org = (
  state: currentOrganization = OrgInitState,
  action: reduxAction
) => {
  switch (action.type) {
    case SET_ORG_DETAILS:
      return { ...action.payload };
    case RESET_ORG_DETAILS:
      return {};
    default:
      return { ...state };
  }
};

export default org;
