import { takeLatest, call } from "redux-saga/effects";
import { FETCH_DOMAIN_DETAILS } from "../actionKeys";
import { domainsAPI } from "../api";
import { reduxAction } from "../interfaces";
import { notifyError } from "../utils";

function* fetchDomainDetailsById(action: reduxAction) {
  const { id: domainId, toggleLoading } = action.payload;
  try {
    const response = yield call(domainsAPI.getDomainDetail, domainId);
    console.log(response);
  } catch (error) {
    yield call(notifyError, error);
  } finally {
    yield call(toggleLoading, false);
  }
}

export default function* domainsSaga() {
  yield takeLatest(FETCH_DOMAIN_DETAILS, fetchDomainDetailsById);
}
