import { takeLatest, call } from "redux-saga/effects";
import { orgAPI } from "../api";
import { FETCH_DASHBOARD_STATS } from "../actionKeys";
import { getOrgId } from "./miscellanious";
import { reduxAction } from "../interfaces";
import { notifyError } from "../utils";

export const selectOrgData = (state: any) => state.org;

function* getDashboardStats(action: reduxAction) {
  const { setStatistics, toggleLoading } = action.payload;
  try {
    const id = yield call(getOrgId);
    if (id) {
      const dashStatResponse = yield call(orgAPI.getDashboardStats, id);
      const { data } = dashStatResponse;
      if (data) {
        let statsArray: any = [];
        const statsKeys = Object.keys(data);
        statsKeys.forEach((key: string) => {
          statsArray.push({
            description: key,
            count: data[key]
          });
        });
        yield call(setStatistics, statsArray);
        yield call(toggleLoading, false);
      }
    }
  } catch (error) {
    yield call(notifyError, error);
  }
}

export default function* dashboardSaga() {
  yield takeLatest(FETCH_DASHBOARD_STATS, getDashboardStats);
}
