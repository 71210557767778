import React from "react";
import styled from "styled-components";

import { Row } from "antd";

const backgroundImg = require("../../images/login_bg.jpg");

const StyledWrapper = styled(Row)`
  min-height: 100vh;
  position: relative;
  background: #001529;
  &::before{
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.1;
    background: url('${backgroundImg}') center center/cover no-repeat fixed padding-box border-box #001529;
  }
`;

const OuterWrapper = (props: any) => {
  return (
    <StyledWrapper type="flex" align="middle" justify="center">
      {props.children}
    </StyledWrapper>
  );
};

export default OuterWrapper;
