import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ProjectFilters, ProjectCard } from "../../components/Projects";
import { Pagination } from "../../components/Generics";
import { getOrgProjects } from "../../actions/projectsActions";
import { LoadingSpinner, SimpleNoData } from "../../components/Generics";
import { ProjectItem } from "../../interfaces";
import { DEFAULT_PAGE_SIZE } from "../../consts";

export const renderProjectCards = ({
  projects,
  handleProjectCardClick
}: any) => {
  if (projects.length === 0) {
    return <SimpleNoData />;
  }
  return (
    <React.Fragment>
      {projects &&
        projects.map((project: ProjectItem) => {
          return (
            <ProjectCard
              key={project.id}
              data={project}
              cardClickHandler={handleProjectCardClick}
            />
          );
        })}
    </React.Fragment>
  );
};

const ProjectsWrapper = (props: any) => {
  const { fetchProjects, projects, projectsCount } = props;
  const [loading, toggleLoading] = useState(true);
  const [offset, setOffset] = useState(0);

  const hadlePaginationChange = (page: number, pageSize: number) => {
    const calculatedOffset = (page - 1) * pageSize;
    setOffset(calculatedOffset);
  };

  useEffect(() => {
    fetchProjects({ bodyParams: { offset }, toggleLoading });
  }, [fetchProjects, offset]);

  const history = useHistory();

  const handleProjectCardClick = (projectId: number) => {
    history.push(`/projects/${projectId}`);
  };

  return (
    <React.Fragment>
      <ProjectFilters projectsCount={projectsCount} />
      <div style={{ margin: "1rem 0" }}>
        {loading ? (
          <LoadingSpinner size={"medium"} flex />
        ) : (
          renderProjectCards({ projects, handleProjectCardClick })
        )}
      </div>
      {projectsCount > DEFAULT_PAGE_SIZE ? (
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <Pagination
            defaultCurrent={1}
            total={projectsCount}
            pageSize={DEFAULT_PAGE_SIZE}
            changeHanlder={
              hadlePaginationChange ? hadlePaginationChange : () => {}
            }
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    projects: state.projects.list,
    projectsCount: state.projects.count
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchProjects: (payload: any) => {
      dispatch(getOrgProjects(payload));
    }
  };
};

const ProjectsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsWrapper);

export { ProjectsContainer };
