import React from "react";
import { Form, Button, Icon, Input } from "antd";
import styled from "styled-components";

import { FooterText, FooterLink } from "./Typography";

import { loginFieldConfig } from "../../interfaces";
import { INPUT_FIELD_CONFIGS } from "../../consts";

const SubmitButton = styled(Button)`
  width: 100%;
`;

const InputField = styled(Input)`
  color: rgba(0, 0, 0, 0.7);
`;

const FormElement: any = (props: any) => {
  const { getFieldDecorator, submitHandler, isLoading } = props;

  return (
    <Form onSubmit={submitHandler}>
      {INPUT_FIELD_CONFIGS.map((config: loginFieldConfig) => {
        return (
          <Form.Item key={config.id}>
            {getFieldDecorator(config.id, {
              rules: config.rule
            })(
              <InputField
                prefix={<Icon type={config.icon} />}
                placeholder={config.placeholder}
                type={config.type}
                autoComplete={"off"}
              />
            )}
          </Form.Item>
        );
      })}
      <Form.Item>
        <SubmitButton type="primary" htmlType="submit" loading={isLoading}>
          {isLoading ? "Logging In" : "Login"}
        </SubmitButton>
      </Form.Item>
      <FooterText>
        {"Don't have an account?"}
        <FooterLink link={"#"} text={"Register"} />
      </FooterText>
    </Form>
  );
};

export default FormElement;
