import React from "react";
import styled from "styled-components";

import { Col } from "antd";

const StyledWrapper = styled(Col)`
  background-color: rgba(95, 95, 95, 0.7);
  border-radius: 9px;
  padding: 16px;
  box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.2) inset,
    3px -4px 15px rgba(0, 0, 0, 0.2) inset;
`;

const InnerWrapper = (props: any) => {
  return (
    <StyledWrapper lg={6} md={10} sm={14} xs={18}>
      {props.children}
    </StyledWrapper>
  );
};

export default InnerWrapper;
