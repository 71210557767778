import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "../reducers/rootReducer";
import rootSaga from "../sagas";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

declare var process: { env: { [key: string]: string | undefined } };

const reduxExtension =
  (window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()) ||
  compose;

const sagaMiddleWare = createSagaMiddleware();

const composedStoreOptions =
  process.env["NODE_ENV"] === "development"
    ? compose(applyMiddleware(sagaMiddleWare), reduxExtension)
    : compose(applyMiddleware(sagaMiddleWare));

export const store = createStore(rootReducer, composedStoreOptions);

export const initSaga = () => {
  sagaMiddleWare.run(rootSaga);
};
