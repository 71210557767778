import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "antd";
import { connect } from "react-redux";
import styled from "styled-components";
import { SimpleNoData } from "../Generics";

const StyledCard = styled(Card)`
  border: 1px solid #2773be82;
  border-radius: 9px;
  background: #31465a;
  transition: all 0.1s cubic-bezier(0.47, 0, 0.75, 0.72);
  transition-property: box-shadow, background, scale, border;
  .ant-card-head {
    border-bottom: 1px solid #1a3246;
  }
  .ant-card-head-title {
    font-weight: bold;
  }
  .ant-card-head-title,
  .ant-card-body {
    color: rgb(121, 165, 207);
  }
  &:hover,
  &:focus {
    transform: scale(1.01);
    box-shadow: 0px 2px 0px rgba(45, 93, 142, 0.36);
    background: #2c4154;
    border-color: #1890ff;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  li {
    margin: 0.5rem 0;
    span {
      &:first-of-type {
        color: #1a90ff;
        margin: 10px;
        font-weight: bold;
      }
    }
  }
`;

const SpaceCards: any = (props: any) => {
  const { spaces } = props;
  const renderSpacesList = () => (
    <React.Fragment>
      {spaces.map((space: any) => {
        const {
          name,
          searches_count: searchesCount,
          projects_count: projectsCount,
          domains_count: domainsCount
        } = space;
        return (
          <Col
            xs={24}
            sm={12}
            lg={8}
            key={space.id}
            style={{ marginBottom: 16 }}
          >
            <Link to={`/spaces/${space.id}`}>
              <StyledCard title={name} bordered={false}>
                <StyledList>
                  <li>
                    <span>{projectsCount || 0}</span>Projects
                  </li>
                  <li>
                    <span>{domainsCount || 0}</span>Domains
                  </li>
                  <li>
                    <span>{searchesCount || 0}</span>Searches
                  </li>
                </StyledList>
              </StyledCard>
            </Link>
          </Col>
        );
      })}
    </React.Fragment>
  );
  return (
    <Row gutter={16} style={{ margin: "16px 0" }}>
      {spaces.length === 0 ? <SimpleNoData /> : renderSpacesList()}
    </Row>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    spaces: state.spaces.list,
    ...ownProps
  };
};

export default connect(mapStateToProps)(SpaceCards);
