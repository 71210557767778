import React, { useEffect } from "react";
import StatisticsRow from "./StatisticsRow";
import { ProjectsContainer } from "./ProjectsContainer";
import BreadCrumbWrapper from "../../hoc/BreadCrumbWrapper";
import { DOC_TITLE_SEPARATOR, APP_NAME } from "../../consts";

const Dashboard = (props: any) => {
  useEffect(() => {
    document.title = `Dashboard ${DOC_TITLE_SEPARATOR} ${APP_NAME}`;
  });

  return (
    <React.Fragment>
      <StatisticsRow />
      <ProjectsContainer />
    </React.Fragment>
  );
};

export default BreadCrumbWrapper()(Dashboard);
