import actionGenerator from "../utils/actionGenerator";
import {
  SET_ORG_DETAILS,
  RESET_ORG_DETAILS,
  GET_ORG_SPACES
} from "../actionKeys";

export const setOrgDetails = actionGenerator(SET_ORG_DETAILS);
export const resetOrgDetails = actionGenerator(RESET_ORG_DETAILS);
export const getOrgSpaces = actionGenerator(GET_ORG_SPACES);
