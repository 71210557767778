import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Typography } from "antd";
import styled from "styled-components";
import { fetchDashboardStats } from "../../actions/appActions";
import { LoadingSpinner } from "../../components/Generics";
import { formatText } from "../../utils";

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
`;

const StatisticCard = styled.div`
  min-height: 6.5rem;
  background: #31465a;
  border-radius: 12px;
  border: 1px solid rgba(26, 144, 255, 0.58);
  padding: 1rem;
  box-shadow: 0 1px 5px rgba(188, 223, 255, 0.58);
  width: calc((100% / 4) - 1rem);
  min-width: 200px;
  margin: 0 1rem 1rem 0;
  h4 {
    color: #7aa5ce;
  }
  span {
    font-size: 1.75rem;
    color: #1a90ff;
  }
`;

const StatisticsRow = (props: any) => {
  const { fetchDashboardStatistics } = props;
  const [statistics, setStatistics] = useState();
  const [loading, toggleLoading] = useState(true);

  useEffect(() => {
    fetchDashboardStatistics({
      setStatistics,
      toggleLoading
    });
  }, [fetchDashboardStatistics]);

  return (
    <CardsWrapper>
      {loading ? (
        <LoadingSpinner size={"large"} />
      ) : (
        <React.Fragment>
          {statistics &&
            statistics.map((item: any) => {
              return (
                <StatisticCard key={item.description}>
                  <Typography.Title level={4} ellipsis>
                    {formatText({
                      text: item.description,
                      delimmeter: "_",
                      excludeLastWord: true
                    })}
                  </Typography.Title>
                  <Typography.Text ellipsis strong>
                    {item.count > 99999 ? "99999+" : item.count}
                  </Typography.Text>
                </StatisticCard>
              );
            })}
        </React.Fragment>
      )}
    </CardsWrapper>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchDashboardStatistics: (payload: any) => {
      dispatch(fetchDashboardStats(payload));
    }
  };
};

export default connect(null, mapDispatchToProps)(StatisticsRow);
