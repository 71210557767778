import React from "react";
import { connect } from "react-redux";
import { Layout, Typography } from "antd";
import styled from "styled-components";

import Menu from "./Menu";

const { Sider } = Layout;

const FixedSider = styled(Sider)`
  position: fixed;
  z-index: 99999;
  transition: width 0.1s ease;
`;

const ProductTitle = styled(Typography.Title)`
  font-family: "Trade Winds";
  color: #fff !important;
  height: 64px;
  margin: 0 auto !important;
  width: 150px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 1rem;
`;

const ShortTitle: any = styled(ProductTitle)`
  width: 50px;
  font-family: "Trade Winds";
`;

const SideNav = (props: any) => {
  const { collapsed, toggleCollapsed, showNav } = props;
  return (
    <React.Fragment>
      {showNav ? (
        <FixedSider
          collapsible
          collapsed={collapsed}
          onCollapse={() => toggleCollapsed(!collapsed)}
        >
          {collapsed ? (
            <ShortTitle level={4} ellipsis={true}>
              AS
            </ShortTitle>
          ) : (
            <ProductTitle level={4} ellipsis={true}>
              API Scanner
            </ProductTitle>
          )}

          <Menu />
        </FixedSider>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    showNav: state.app.showNavs,
    ...ownProps
  };
};

export default connect(mapStateToProps)(SideNav);
