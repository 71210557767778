import { select, put, call } from "redux-saga/effects";
import { default as _ } from "lodash";
import { orgAPI } from "../api";
import { setOrgDetails } from "../actions/orgActions";
import { showNotification } from "../actions/appActions";
import { ERROR_NOTIFICATION_DURATION } from "../consts";

const selectOrgData = (state: any) => state.org;

export function* getOrgDetails() {
  const orgResponse = yield call(orgAPI.getOrganizations);
  const { status, data } = orgResponse;
  if (status) {
    if (data && data.results && data.results.length) {
      yield put(setOrgDetails(orgResponse.data.results[0]));
    } else {
      yield put(
        showNotification({
          message: "No organization detail found",
          duration: ERROR_NOTIFICATION_DURATION
        })
      );
    }
  }
}

export function* getOrgId() {
  let org = yield select(selectOrgData);
  if (_.isEmpty(org)) {
    yield call(getOrgDetails);
    org = yield select(selectOrgData);
  }
  const { id } = org;
  return id;
}
