import { combineReducers } from "redux";

import app from "./appReducer";
import org from "./orgReducer";
import projects from "./projectsReducer";
import spaces from "./spacesReducer";

const rootReducer = combineReducers({
  app,
  org,
  spaces,
  projects
});

export default rootReducer;
