import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { connect } from "react-redux";
import styled from "styled-components";
import { getProjectLinks } from "../../../actions/projectsActions";

const DarkTable = styled(Table)`
  .ant-table {
    color: #7aa5cf;
    overflow-y: auto;
    .ant-table-thead {
      & > tr {
        & > th {
          color: rgb(122, 165, 207);
          background: #31465a;
          border-bottom: 1px solid #29558d;
        }
      }
    }
    .ant-table-tbody {
      & > tr {
        & > td {
          border-bottom: 1px solid #2a548c;
        }
        &.ant-table-row-selected,
        &:hover {
          td {
            background: #05162a;
          }
        }
      }
    }
  }
  .ant-table-pagination {
    .ant-pagination-item {
      background-color: #31465a;
      border: 1px solid #2d5d8e;
      a {
        color: rgb(120, 165, 208);
      }
      &:hover,
      &:focus {
        border-color: #1890ff;
      }
    }
    .ant-pagination-item-active {
      background: #31465a;
      border-color: #1890ff;
    }
    .ant-pagination-item-link {
      color: rgb(120, 165, 208);
      border-color: #2d5d8e;
      background: #31465a;
    }
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      .ant-pagination-item-container {
        .ant-pagination-item-ellipsis {
          color: rgb(120, 165, 208);
        }
      }
    }
  }
`;

const columns = [
  {
    title: "Method",
    dataIndex: "method"
  },
  {
    title: "Path",
    dataIndex: "path"
  },
  {
    title: "Query Params",
    dataIndex: "query_params",
    render: (textObj: any) => <span>{JSON.stringify(textObj)}</span>
  },
  {
    title: "Headers",
    dataIndex: "headers",
    render: (textObj: any) => <span>{JSON.stringify(textObj)}</span>
  },
  {
    title: "Body",
    dataIndex: "body"
  },
  {
    title: "Proxy",
    dataIndex: "proxy"
  },
  {
    title: "Custom Headers",
    dataIndex: "custom_headers",
    render: (textObj: any) => <span>{JSON.stringify(textObj)}</span>
  },
  {
    title: "Custom Body",
    dataIndex: "custom_body"
  }
];

let selectedLinkIds: Array<any> = [];

const LinksInfo = (props: any) => {
  const [data, setData] = useState([]);
  const [loading, toggleLoading] = useState(true);
  const { id, fetchLinks } = props;

  const selectionHadlers = {
    onChange: (rowKey: Array<any>) => {
      selectedLinkIds = [...rowKey];
    }
  };

  useEffect(() => {
    fetchLinks({ id, toggleLoading, setData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DarkTable
      loading={loading}
      rowSelection={{
        ...selectionHadlers
      }}
      columns={columns}
      dataSource={data}
    />
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchLinks: (payload: any) => {
      dispatch(getProjectLinks(payload));
    }
  };
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    ...ownProps
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinksInfo);
