import React from "react";
import styled from "styled-components";

import { Typography } from "antd";

const StyledTitle = styled(Typography.Title)`
  font-family: "Trade Winds";
  text-align: center;
  color: rgba(255, 255, 255, 0.65) !important;
  user-select: none;
`;

const StyledText = styled(Typography.Text)`
  text-align: center;
  padding: 12px 0;
  color: rgba(255, 255, 255, 0.5) !important;
  user-select: none;
  display: block;
`;

const StyledLink = styled.a`
  margin: 0 4px;
  font-weight: bold;
`;

export const Title = (props: any) => {
  return <StyledTitle level={2}>{props.children}</StyledTitle>;
};

export const FooterText = (props: any) => {
  return <StyledText>{props.children}</StyledText>;
};

export const FooterLink = (props: any) => {
  const { link, text } = props;
  return <StyledLink href={link}>{text}</StyledLink>;
};
