import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import { SpaceCards, CreateSpaceModal } from "../../components/Spaces";
import { Pagination, LoadingSpinner } from "../../components/Generics";
import BreadCrumbWrapper from "../../hoc/BreadCrumbWrapper";
import { APP_NAME, DOC_TITLE_SEPARATOR, DEFAULT_PAGE_SIZE } from "../../consts";
import { getOrgSpaces } from "../../actions/orgActions";
import { toggleCreateSpaceModel } from "../../actions/appActions";

const SpacesList = (props: any) => {
  const { spacesCount, fetchSpaces, showCreateSpaceModal } = props;
  const [offset, setOffset] = useState(0);
  const [loading, toggleSpaceLoading] = useState(true);

  const hadlePaginationChange = (page: number, pageSize: number) => {
    const calculatedOffset = (page - 1) * pageSize;
    setOffset(calculatedOffset);
  };

  const handleCreateButtonClick = () => {
    showCreateSpaceModal();
  };

  useEffect(() => {
    document.title = `Spaces ${DOC_TITLE_SEPARATOR} ${APP_NAME}`;
    toggleSpaceLoading(true);
    fetchSpaces({ toggleSpaceLoading, offset });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, fetchSpaces]);

  return (
    <React.Fragment>
      {loading ? (
        <LoadingSpinner size={"large"} flex />
      ) : (
        <React.Fragment>
          <Button
            icon={"plus"}
            shape={"round"}
            type={"primary"}
            style={{ float: "right", margin: "14px 0" }}
            onClick={handleCreateButtonClick}
          >
            Create New Space
          </Button>
          <SpaceCards />
          {spacesCount > DEFAULT_PAGE_SIZE ? (
            <div style={{ textAlign: "center", margin: "2rem 0" }}>
              <Pagination
                defaultCurrent={1}
                total={spacesCount}
                pageSize={DEFAULT_PAGE_SIZE}
                changeHanlder={hadlePaginationChange}
              />
            </div>
          ) : null}
        </React.Fragment>
      )}
      <CreateSpaceModal />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    spacesCount: state.spaces.count
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchSpaces: (payload: any) => {
      dispatch(getOrgSpaces(payload));
    },
    showCreateSpaceModal: () => {
      dispatch(toggleCreateSpaceModel(true));
    }
  };
};

export default BreadCrumbWrapper()(
  connect(mapStateToProps, mapDispatchToProps)(SpacesList)
);
