import actionGenerator from "../utils/actionGenerator";
import {
  GET_ALL_ORG_PROJECTS,
  RESET_PROJECTS,
  SET_PROJECTS,
  SET_PROJECTS_COUNT,
  GET_PROJECT_DETAIL,
  GET_PROJECT_LINKS
} from "../actionKeys";

export const getOrgProjects = actionGenerator(GET_ALL_ORG_PROJECTS);
export const resetProjects = actionGenerator(RESET_PROJECTS);
export const setProjects = actionGenerator(SET_PROJECTS);
export const setProjectsCount = actionGenerator(SET_PROJECTS_COUNT);
export const getProjectDetail = actionGenerator(GET_PROJECT_DETAIL);
export const getProjectLinks = actionGenerator(GET_PROJECT_LINKS);
