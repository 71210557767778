import React from "react";
import { Pagination } from "antd";
import styled from "styled-components";
import { DEFAULT_PAGE_SIZE } from "../../consts";

export const DarkPagination = styled(Pagination)`
  .ant-pagination-item {
    background-color: #31465a;
    border: 1px solid #2d5d8e;
    a {
      color: rgb(120, 165, 208);
    }
    &:hover,
    &:focus {
      border-color: #1890ff;
    }
  }
  .ant-pagination-item-active {
    background: #31465a;
    border-color: #1890ff;
  }
  .ant-pagination-item-link {
    color: rgb(120, 165, 208);
    border-color: #2d5d8e;
    background: #31465a;
  }
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    .ant-pagination-item-container {
      .ant-pagination-item-ellipsis {
        color: rgb(120, 165, 208);
      }
    }
  }
`;

export const PaginationComponent = (props: any) => {
  const { defaultCurrent, total, pageSize, changeHanlder } = props;
  return (
    <DarkPagination
      defaultCurrent={defaultCurrent}
      total={total}
      pageSize={pageSize ? pageSize : DEFAULT_PAGE_SIZE}
      onChange={changeHanlder}
    />
  );
};
