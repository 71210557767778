import React, { useEffect, useState } from "react";
import { Typography, Tag } from "antd";
import { getProjectDetail } from "../../../actions/projectsActions";
import { connect } from "react-redux";
import styled from "styled-components";
import { default as _ } from "lodash";
import { SimpleNoData, LoadingSpinner } from "../../../components/Generics";
import { default as moment } from "moment";
import { SCANSTATUSTEXTS } from "../../../consts";

const StyledHeading = styled(Typography.Title)`
  &.ant-typography {
    color: #7aa5ce;
  }
`;

const DomainInfo = (props: any) => {
  const { id, fetchDetails } = props;
  const [loading, toggleLoading] = useState(true);
  const [details, setDetails] = useState({});

  useEffect(() => {
    toggleLoading(true);
    fetchDetails({ id, toggleLoading, setDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDetails = () => {
    const {
      domain,
      excluded_paths: excludedPaths,
      comment,
      tags,
      status,
      creator,
      created_on: createdOn
    }: any = details;
    return (
      <React.Fragment>
        {_.isEmpty(details) ? (
          <SimpleNoData />
        ) : (
          <div>
            <StyledHeading ellipsis level={4}>
              Domain Id: {domain}
            </StyledHeading>
            <div>
              <p>
                <strong>Excluded Paths:</strong> {excludedPaths}
              </p>
              <p>
                <strong>Comment:</strong> {comment}
              </p>
              <p>
                <span>
                  <strong>Tags: </strong>
                </span>
                {tags &&
                  tags.map((tag: any, index: number) => {
                    return <Tag key={`${tag.slug}-${index}`}>{tag.name}</Tag>;
                  })}
              </p>
              <p>
                <strong>Status:</strong>
                {SCANSTATUSTEXTS[status]}
              </p>
              <p>
                <strong>Created On: </strong>
                {moment(createdOn).format("DD-MMM-YYYY")}
              </p>
              <p>
                <strong>Created By: </strong>
                {creator}
              </p>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingSpinner size={"medium"} flex></LoadingSpinner>
      ) : (
        renderDetails()
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchDetails: (action: any) => {
      dispatch(getProjectDetail(action));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainInfo);
