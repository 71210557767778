import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProjectsBySpaceId } from "../../../actions/spacesActions";
import { ProjectItem } from "../../../interfaces";
import { ProjectCard } from "../../../components/Projects";
import {
  LoadingSpinner,
  Pagination,
  SimpleNoData
} from "../../../components/Generics";
import { DEFAULT_PAGE_SIZE } from "../../../consts";

const renederProjectsCards = ({ projects, handleProjectCardClick }: any) => {
  if (projects.length === 0) {
    return <SimpleNoData />;
  }
  return (
    <React.Fragment>
      {projects &&
        projects.map((project: ProjectItem) => {
          return (
            <ProjectCard
              key={project.id}
              data={project}
              cardClickHandler={handleProjectCardClick}
            />
          );
        })}
    </React.Fragment>
  );
};

const ProjectsList = (props: any) => {
  const { id, getProjects } = props;
  const [loading, toggleLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [projectsCount, setProjectsCount] = useState(0);
  const stateSetters = {
    setProjects,
    setProjectsCount,
    toggleLoading
  };
  const [offset, setOffset] = useState(0);

  const hadlePaginationChange = (page: number, pageSize: number) => {
    const calculatedOffset = (page - 1) * pageSize;
    setOffset(calculatedOffset);
  };

  const history = useHistory();

  const handleProjectCardClick = (projectId: number) => {
    history.push(`/projects/${projectId}`);
  };

  useEffect(() => {
    toggleLoading(true);
    getProjects({ id, offset, stateSetters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjects, offset]);

  return (
    <React.Fragment>
      {loading ? (
        <LoadingSpinner size={"small"} flex />
      ) : (
        renederProjectsCards({ projects, handleProjectCardClick })
      )}
      {projectsCount > DEFAULT_PAGE_SIZE ? (
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <Pagination
            defaultCurrent={1}
            total={projectsCount}
            pageSize={DEFAULT_PAGE_SIZE}
            changeHanlder={
              hadlePaginationChange ? hadlePaginationChange : () => {}
            }
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getProjects: (payload: any) => {
      dispatch(getProjectsBySpaceId(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);
