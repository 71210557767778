import React from "react";
import { Button } from "antd";
import styled from "styled-components";
import { default as moment } from "moment";

const StyledList = styled.ul`
  list-style: none;
  padding: 12px 24px;
  border: 1px solid #2773be82;
  li {
    padding: 1rem 0;
    &:not(:last-child) {
      border-bottom: 1px dashed #4e6a86de;
    }
    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > p {
        width: 200px;
        margin: 0;
      }
      @media (max-width: 623px) {
        flex-direction: column;
      }
    }
  }
`;

const CustomList = (props: any) => {
  const { data } = props;
  return (
    <StyledList>
      {data &&
        data.map((datum: any) => {
          const { id, name, created_on, project: projectId, query } = datum;
          return (
            <li key={id} id={id}>
              <div>
                {name && <p>{name}</p>}
                {query && <p>{query}</p>}
                <p>Created On: {moment(created_on).format("DD-MMM-YYYY")}</p>
                {projectId &&
                  (projectId > 0 ? (
                    <Button type="primary">View Project</Button>
                  ) : (
                    <Button type="primary">Create Project</Button>
                  ))}
              </div>
            </li>
          );
        })}
    </StyledList>
  );
};

export default CustomList;
