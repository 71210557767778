import { takeLatest, call } from "redux-saga/effects";
import { GET_PROJECT_DETAIL, GET_PROJECT_LINKS } from "../actionKeys";
import { projectsAPI } from "../api";
import { reduxAction } from "../interfaces";
import { notifyError } from "../utils";
import { default as _ } from "lodash";

function* fetchProjectDetailsById(action: reduxAction) {
  const { id: projectId, toggleLoading, setDetails } = action.payload;
  try {
    const response = yield call(projectsAPI.getDetailsById, projectId);
    const { data } = response;
    if (!_.isEmpty(data)) {
      yield call(setDetails, data);
    }
  } catch (error) {
    yield call(notifyError, error);
  } finally {
    yield call(toggleLoading, false);
  }
}

function* fetchProjectLinks(action: reduxAction) {
  const { id: projectId, toggleLoading, setData } = action.payload;
  try {
    const response = yield call(projectsAPI.getProjectLinks, projectId);
    const { data } = response;
    if (data && data.results) {
      const { results } = data;
      results.forEach((link: any) => {
        link.key = link.id;
      });
      yield call(setData, results);
    }
  } catch (error) {
    yield call(notifyError, error);
  } finally {
    yield call(toggleLoading, false);
  }
}

export default function* projectsSaga() {
  yield takeLatest(GET_PROJECT_DETAIL, fetchProjectDetailsById);
  yield takeLatest(GET_PROJECT_LINKS, fetchProjectLinks);
}
