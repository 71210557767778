import React, { useEffect } from "react";
import { Tabs } from "antd";
import { ProjectInfo, LinksInfo } from "./DetailsContainer";
import styled from "styled-components";
import { DOC_TITLE_SEPARATOR, APP_NAME } from "../../consts";
import wrapBreadcrumb from "../../hoc/BreadCrumbWrapper";

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  margin: 1rem 0;
  .ant-tabs-bar {
    border-color: #1890ff5c;
    margin-bottom: 0;
    .ant-tabs-nav-container {
      .ant-tabs-nav {
        .ant-tabs-tab {
          width: calc((100vw / 3) - 108px);
          min-width: 80px;
          border: 1px solid #2773be82;
          background: #31465a;
          color: #6890b6;
          &:hover,
          &.ant-tabs-tab-active {
            color: #1890ff;
          }
          &.ant-tabs-tab-active {
            border-color: #1890ff;
          }
        }
      }
    }
  }
  .ant-tabs-tabpane {
    background: #00162a0d;
    color: #6890b6;
    padding: 1.5rem 0.5rem;
    border-left: 1px solid #1890ff5c;
    border-right: 1px solid #1890ff5c;
    border-bottom: 1px solid #1890ff5c;
  }
`;

const ProjectDetails = (props: any) => {
  const { id } = props;

  useEffect(() => {
    document.title = `Projects ${DOC_TITLE_SEPARATOR} ${APP_NAME}`;
  }, []);

  const tabChnageHandler = (key: any) => {
    console.log(key);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <ProjectInfo id={id} />
        <div></div>
      </div>
      <div>
        <StyledTabs onChange={tabChnageHandler} type="card">
          <TabPane tab="Links" key="2">
            <LinksInfo id={id} />
          </TabPane>
        </StyledTabs>
      </div>
    </React.Fragment>
  );
};

export default wrapBreadcrumb()(ProjectDetails);
