import React from "react";
import { Icon } from "antd";
import styled from "styled-components";

interface LoadingSpinnerProps {
  size: "small" | "medium" | "large";
  flex?: boolean;
}

const LargeLoader = styled(Icon)`
  line-height: 6.5rem;
  font-weight: bold;
  font-size: 4rem;
`;

const MediumLoader = styled(LargeLoader)`
  line-height: unset;
  font-size: 3rem;
`;

const SmallLoader = styled(MediumLoader)`
  font-size: 1rem;
`;

const SIZE_COMPONENT_MAP = {
  small: SmallLoader,
  medium: MediumLoader,
  large: LargeLoader
};

const flexifyLoader = (Element: any) => {
  return styled(Element)`
    display: flex;
    align-items: center;
    justify-content: center;
  `;
};

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { size, flex } = props;
  const SpinnerElement = SIZE_COMPONENT_MAP[size];
  if (flex) {
    const LoaderElement = flexifyLoader(SpinnerElement);
    return <LoaderElement type={"loading"} />;
  }
  return <SpinnerElement type={"loading"} />;
};

export default LoadingSpinner;
