import React from "react";
import { useHistory } from "react-router-dom";
import { Layout, Avatar, Button } from "antd";
import { connect } from "react-redux";
import styled from "styled-components";
import { getOrgSpaces } from "../../actions/orgActions";
import {
  logout,
  toggleAuthLoader,
  toggleDiscoverDomainModel
} from "../../actions/appActions";
import { DiscoverDomainModal } from "../Modals";

const { Header } = Layout;

const StyledHeaderNav = styled(Header)`
  background: #00162a;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  z-index: 998;
  width: 100vw;
`;

const StyledAvatar = styled(Avatar)`
  border: 2px solid #8c8c8c;
  background: rgba(128, 128, 128, 0.4);
`;

const TopMenuWrapper = styled.div`
  float: right;
  margin: 0 15px 0 0;
  padding: 0 8px;
  cursor: pointer;

  height: 64px;
  display: inline-block;
  button {
    color: #8c8c8c;
    border-color: #8c8c8c;
    margin-left: 0.2rem;
  }
`;

const MarginnedButton = styled(Button)`
  margin: 0 1rem;
`;

const HeaderNav = (props: any) => {
  const {
    logoutUser,
    isLoading,
    collapsed,
    domainModalVisibility,
    toggleModalVisibility,
    showNav,
    getSpacesData
  } = props;
  const history = useHistory();

  const handleDiscoverDomainClick = () => {
    toggleModalVisibility(!domainModalVisibility);
  };

  return (
    <React.Fragment>
      {showNav ? (
        <React.Fragment>
          <StyledHeaderNav
            style={{
              paddingRight: collapsed ? 80 : 200
            }}
          >
            <MarginnedButton
              type={"danger"}
              size={"small"}
              icon={"search"}
              onClick={handleDiscoverDomainClick}
            >
              {"Discover Domains"}
            </MarginnedButton>
            <TopMenuWrapper>
              <StyledAvatar
                size="large"
                icon="user"
                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              />
              <Button
                type={"ghost"}
                size={"small"}
                onClick={logoutUser.bind(null, history)}
                loading={isLoading}
              >
                {isLoading ? "Logging Out" : "Log Out"}
              </Button>
            </TopMenuWrapper>
          </StyledHeaderNav>
          <DiscoverDomainModal getSpacesData={getSpacesData} />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.app.showAuthLoader,
    collapsed: state.app.sidebarCollapsed,
    domainModalVisibility: state.app.showDiscoverDomainModal,
    showNav: state.app.showNavs
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: ({ push }: any) => {
      dispatch(toggleAuthLoader(true));
      dispatch(logout({ push }));
    },
    toggleModalVisibility: (flag: boolean) => {
      dispatch(toggleDiscoverDomainModel(flag));
    },
    getSpacesData: (payload: any) => {
      dispatch(getOrgSpaces(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
