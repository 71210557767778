import axios from "axios";
import { Endpoint } from "../enums";
import { store } from "../store";
import {
  showNavs,
  showNotification,
  redirectToLogin
} from "../actions/appActions";
import { ERROR_NOTIFICATION_DURATION } from "../consts";

const { NAMESPACE, VERSION } = Endpoint;

const originUrl = VERSION ? `/${NAMESPACE}/${VERSION}/` : `/${NAMESPACE}/`;

const httpClient = axios.create({
  baseURL: originUrl
});

httpClient.interceptors.response.use(
  function(response) {
    const { config } = response;
    if (config && config.url !== "/logout") {
      setTimeout(() => {
        store.dispatch(showNavs(true));
      }, 500);
    }
    return response;
  },
  function(error) {
    if (error.response) {
      if (401 === error.response.status) {
        store.dispatch(redirectToLogin());
      } else {
        let errorMessage = "Something went wrong";
        if (error.response.data && error.response.data.detail) {
          if (error.response.data.detail instanceof Array) {
            errorMessage = error.response.data.detail.join("\n");
          } else {
            errorMessage = error.response.data.detail;
          }
        }
        store.dispatch(
          showNotification({
            message: errorMessage,
            duration: ERROR_NOTIFICATION_DURATION
          })
        );
      }
    }
    return Promise.reject(error);
  }
);

export default httpClient;
